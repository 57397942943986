/* eslint-disable no-irregular-whitespace */
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import {
  Box,
  Card,
  Heading,
  Image,
  Link as RebassLink,
  Text,
} from 'rebass/styled-components'

import { Layout } from '../../components/layout'
import { SEO } from '../../components/seo'
import { Hero } from '../../components/hero'
import { ContentSection } from '../../sections/content'
import reliefBg from '../../images/relief-bg.png'
import pdfIcon from '../../images/bi_file-pdf.svg'

const NanotechnologiePage = () => {
  const data = useStaticQuery(graphql`
    {
      heroBg: file(relativePath: { regex: "/nabizime-hero-bg.jpg/" }) {
        childImageSharp {
          gatsbyImageData(quality: 90)
        }
      }
    }
  `)
  const heroBg = data.heroBg.childImageSharp.gatsbyImageData.images.fallback.src

  return (
    <Layout>
      <SEO
        title="Nanotechnologie"
        description="Proč používat oleje a laky s nanočásticemi"
      />

      <Hero
        image={heroBg}
        bgPosition="center 60%"
        title="Nanotechnologie"
        text="Proč používat oleje a laky s nanočásticemi"
        leadText
      />

      <ContentSection
        sx={{
          backgroundImage: `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%), url(${reliefBg})`,
          backgroundSize: '100%, 50%',
        }}
      >
        <Box sx={{ position: 'relative', mt: -6 }}>
          <Card
            sx={{
              gridTemplateColumns: ['1fr', 'auto 1fr'],
              gap: 4,
              alignItems: 'center',
            }}
          >
            <Text as="p" sx={{ fontSize: '1.125rem', fontWeight: 'bold' }}>
              S nanotechnologií se dnes setkáváme téměř na každém kroku. Jde o
              technický obor, který se zabývá tvorbou a využíváním technologií v
              řádech miliardtin metru, tedy nanometrů. V praxi se jedná o
              vkládání velmi malých částic (zhruba stotisíckrát menších, než je
              lidský vlas) do materiálů, díky čemuž se zlepšují vlastnosti
              výrobků a jejich životnost.
            </Text>
          </Card>
        </Box>
      </ContentSection>

      <ContentSection sx={{ pt: 0 }}>
        <Text as="p">
          Procesy nanotechnologie lze využít například v textilním průmyslu,
          zdravotnictví či optickém průmyslu, ale také při výrobě laků a olejů
          na dřevo. Nanočástice po spojení s bází a aplikací vytváří na povrchu
          dřeva vrstvu extrémně odolnou proti poškrábání, otěru, působení
          vnějších vlivů i proti chemikáliím.
        </Text>
      </ContentSection>

      <ContentSection dark>
        <Text as="p">
          Největší výhodou nanočástic v oleji či laku je to, že se aplikuje
          pouze malá vrstva. Výsledná ochrana je co do kvality a odolnosti
          přitom mnohonásobně lepší než při používání produktů, které
          nanočástice neobsahují. Aplikace laků a olejů s nanočásticemi je díky
          tomu jednodušší a má menší dopad na životní prostředí.
        </Text>

        <Text as="p" sx={{ mb: 0 }}>
          Hlavní výhody laků a olejů s nanočásticemi:
        </Text>

        <Text as="ul">
          <li>vysoká penetrace,</li>
          <li>vysoká přilnavost,</li>
          <li>excelentní otěruvzdornost, tvrdost,</li>
          <li>dlouhá životnost,</li>
          <li>snadná aplikace, </li>
          <li>velmi dobře se rozlévají po povrchu,</li>
          <li>výborně zasychají a vytvrzují,</li>
          <li>přirozený a elegantní vzhled,</li>
          <li>jsou trvale udržitelné.</li>
        </Text>
      </ContentSection>

      <ContentSection>
        <Heading variant="contentSectionTitle" as="h2">
          Keramikou vyztužené laky – mana pro vaši podlahu
        </Heading>

        <Text as="p">
          Hitem mezi oleji a laky s nanočásticemi jsou podlahové laky vyztužené
          keramikou. Keramické částice jsou tisíckrát menší než polyuretanové
          částice.
        </Text>

        <Text as="p">
          Aplikace takového laku je stejně jednoduchá jako aplikace jakéhokoliv
          jiného laku. Na povrchu podlahy vytvoří kompaktní, tenký film, který
          se vyznačuje silnou pevností a trvalou udržitelností.
        </Text>

        <Text as="p">
          V porovnání s běžnými dvousložkovými polyuretanovými laky jsou až o 20
          % odolnější proti odření a poškrábání.
        </Text>
      </ContentSection>

      <ContentSection dark>
        <Text as="p" sx={{ mb: 0 }}>
          Keramikou vyztužené laky jsou také daleko šetrnější k životnímu
          prostředí, a to zejména proto, že:
        </Text>

        <Text as="ul">
          <li>
            je v nich o 30 % méně petrochemických sloučenin než v PU lacích,
          </li>
          <li>namísto petrochemikálií obsahují křemík,</li>
          <li>mají nižší obsah těkavých látek (VOC),</li>
          <li>nefungují jako akcelerátor nebo palivo.</li>
        </Text>
      </ContentSection>

      <ContentSection>
        <Text as="p">
          Díky tomu splňuje keramické vyztužení veškeré požadavky na klasifikaci
          EC1 a BREAM/BREEM.
        </Text>

        <RebassLink
          variant="linkButton"
          href="https://drive.google.com/file/d/1iS1hb8h6L_S_t0t3PBkqsqZm57hPdYgO/view"
          target="_blank"
        >
          <Image src={pdfIcon} alt="" aria-hidden />
          Zobrazit prezentaci
        </RebassLink>
      </ContentSection>
    </Layout>
  )
}

export default NanotechnologiePage
